var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "backend-customer-autocomplete" },
    [
      _c("v-autocomplete", {
        attrs: {
          items: _vm.managerList,
          loading: _vm.loading,
          "search-input": _vm.search,
          color: "info",
          "item-text": "text",
          "item-value": "id",
          label: _vm.managerText,
          "return-object": "",
          required: "",
          "close-on-content-click": "",
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.search = $event
          },
          "update:search-input": function ($event) {
            _vm.search = $event
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.fireEnterEvent()
          },
          blur: function ($event) {
            return _vm.pushAccountManager()
          },
          "click:clear": function ($event) {
            return _vm.removeAccountManager()
          },
        },
        model: {
          value: _vm.manager,
          callback: function ($$v) {
            _vm.manager = $$v
          },
          expression: "manager",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
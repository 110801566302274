var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "backend-agent-autocomplete" },
    [
      _c("v-autocomplete", {
        attrs: {
          items: _vm.agentList,
          loading: _vm.loading,
          "search-input": _vm.search,
          color: _vm.color,
          "item-text": "name",
          "item-value": "id",
          label: _vm.agentText,
          "return-object": "",
          "close-on-content-click": "",
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.search = $event
          },
          "update:search-input": function ($event) {
            _vm.search = $event
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.fireEnterEvent()
          },
          blur: function ($event) {
            return _vm.pushAgent()
          },
          "click:clear": function ($event) {
            return _vm.removeAgent()
          },
        },
        model: {
          value: _vm.agent,
          callback: function ($$v) {
            _vm.agent = $$v
          },
          expression: "agent",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }